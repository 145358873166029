import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './AboutUs.css';

export default function About() {

  window.scrollTo(0, 0);
  return (
    <>
      <Header />
      <div className="about-us">
        <div className="about-content">
          <h1 className="about-heading">About GlobeMcqs</h1>
          <p className="about-text">
            Welcome to GlobeMcqs, your ultimate destination for comprehensive MCQ preparation across a wide range of subjects. Whether you are preparing for competitive exams, academic tests, or simply looking to enhance your knowledge, GlobeMcqs is here to support you every step of the way.
          </p>

          <div className="about-section">
            <h2 className="about-subheading">Our Mission</h2>
            <p className="about-text">
              At GlobeMcqs, our mission is to provide high-quality, accurate, and up-to-date multiple-choice questions (MCQs) to help learners excel in their exams. We believe that effective preparation is the key to success, and our extensive database of MCQs is designed to cater to various subjects and levels, ensuring that you have the resources you need to achieve your goals.
            </p>
          </div>

          <div className="about-section">
            <h2 className="about-subheading">What We Offer</h2>
            <ul className="about-list">
              <li className="about-list-item"><strong>Diverse Subject Coverage:</strong> Our platform offers MCQs across a wide array of subjects, including but not limited to Science, Mathematics, History, Geography, and more. We are constantly updating and expanding our question bank to cover the latest syllabus and exam patterns.</li>
              <li className="about-list-item"><strong>User-Friendly Interface:</strong> GlobeMcqs features an intuitive and easy-to-navigate interface, making it simple for users to find and practice the MCQs they need. Our platform is designed to facilitate an engaging and efficient study experience.</li>
              <li className="about-list-item"><strong>Personalized Learning:</strong> With our advanced features, you can track your progress, identify areas for improvement, and tailor your study sessions to meet your individual needs. Our goal is to help you build confidence and achieve your desired results.</li>
            </ul>
          </div>

          <div className="about-section">
            <h2 className="about-subheading">Why Choose Us?</h2>
            <ul className="about-list">
              <li className="about-list-item"><strong>Expertly Curated Content:</strong> Our MCQs are curated by subject matter experts to ensure accuracy and relevance. We prioritize quality and strive to provide questions that are both challenging and reflective of real exam conditions.</li>
              <li className="about-list-item"><strong>Accessible Anytime, Anywhere:</strong> GlobeMcqs is available online, allowing you to access our resources from any device, at any time. Whether you're at home or on the go, you can continue your preparation without interruption.</li>
              <li className="about-list-item"><strong>Community and Support:</strong> Join our growing community of learners and educators. We are committed to providing excellent support and continuously improving our platform based on user feedback.</li>
            </ul>
          </div>

          <div className="about-section">
            <h2 className="about-subheading">Join Us</h2>
            <p className="about-text">
              At GlobeMcqs, we are dedicated to helping you achieve your academic and professional aspirations. Explore our vast collection of MCQs, take advantage of our interactive features, and start your journey toward success today.
            </p>
            <p className="about-text">
              Thank you for choosing GlobeMcqs.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
