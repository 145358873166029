import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const MCQCard = ({ mcq, showAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option, isCorrect) => {
    if (!showAnswer) { // Only allow clicking if answers are not shown
      setSelectedOption({ option, isCorrect });
    }
  };

  return (
    <Card 
      style={{ 
        borderRadius: '12px', 
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', 
        backgroundColor: '#f9f9f9', 
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        maxWidth: '850px', // Fixed width
        margin: 'auto', 
        marginBottom: '30px', 
        padding: '20px', // Adjust padding for better content spacing
        '&:hover': {
          transform: showAnswer ? 'none' : 'scale(1.02)', // Disable hover effect when answers are shown
          boxShadow: showAnswer ? 'none' : '0 8px 16px rgba(0, 0, 0, 0.15)',
        }
      }}
    >
      <CardContent>
        <Typography 
          variant="h5" 
          style={{ 
            marginBottom: '12px', 
            fontWeight: 700, 
            color: '#2c3e50',
            marginBottom: "20px"
          }}
        >
          {mcq.question}
        </Typography>

        <Grid container spacing={2} style={{ textAlign: 'center' }}>
          {[mcq.option1, mcq.option2, mcq.option3, mcq.option4].map((option, index) => (
            <Grid item xs={12} key={index}>
              <Typography 
                variant="body1" 
                style={{ 
                  color: '#34495e', 
                  padding: '8px 0', 
                  backgroundColor: selectedOption?.option === option 
                    ? selectedOption?.isCorrect 
                      ? '#d4edda' // Green background for correct
                      : '#f8d7da' // Red background for incorrect
                    : '#ecf0f1', // Default background
                  borderRadius: '8px', 
                  paddingLeft: '12px',
                  width: "100%", // Full width of container
                  display: 'inline-block', // Make sure text stays inline with padding and border radius
                  maxWidth: '500px', // Set a max width to prevent stretching
                  margin: '0 auto', // Center horizontally
                  cursor: showAnswer ? 'default' : 'pointer', // Disable pointer cursor when answers are shown
                  pointerEvents: showAnswer ? 'none' : 'auto' // Disable pointer events when answers are shown
                }}
                onClick={() => !showAnswer && handleOptionClick(option, option === mcq.correct_answer)}
              >
               {option}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {showAnswer && (
          <Typography 
            variant="subtitle1" 
            style={{ 
              color: '#27ae60', 
              fontWeight: 600,
              marginTop: '16px'
            }}
          >
            Correct Answer: {mcq.correct_answer}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MCQCard;
