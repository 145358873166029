import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Assets/logo.jpeg';
import axios from 'axios';

export default function Header({ onSubjectSelect, onSubcategorySelect, selectedSubject }) {
    const [subjects, setSubjects] = useState([]);
    const [subjectHasSubcategories, setSubjectHasSubcategories] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [subcategoriesDropdown, setSubcategoriesDropdown] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Detect if the device is mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch the list of subjects and their subcategories from the API on page load
    useEffect(() => {
        axios.get('https://backend.globemcqs.com/api/subjects/')
            .then(response => {
                setSubjects(response.data);

                // For each subject, check if it has subcategories
                response.data.forEach(subject => {
                    axios.get(`https://backend.globemcqs.com/api/subcategories/`)
                        .then(subcategoryResponse => {
                            const filteredSubcategories = subcategoryResponse.data.filter(subcategory => subcategory.subject === subject.id);
                            setSubjectHasSubcategories(prevState => ({
                                ...prevState,
                                [subject.id]: filteredSubcategories.length > 0
                            }));
                        })
                        .catch(error => {
                            console.error("Error fetching subcategories:", error);
                        });
                });
            })
            .catch(error => {
                console.error("Error fetching subjects:", error);
            });
    }, []);

    // Fetch subcategories for the hovered or clicked subject
    const fetchSubcategories = (subjectId) => {
        axios.get(`https://backend.globemcqs.com/api/subcategories/`)
            .then(response => {
                const filteredSubcategories = response.data.filter(subcategory => subcategory.subject === subjectId);
                setSubcategoriesDropdown(filteredSubcategories);
            })
            .catch(error => {
                console.error("Error fetching subcategories:", error);
            });
    };

    // Handle subject click/tap for mobile
    const handleSubjectClick = (subjectId) => {
        if (dropdownVisible === subjectId) {
            setDropdownVisible(null); // Close the dropdown if clicked again
        } else {
            setDropdownVisible(subjectId); // Open dropdown
            fetchSubcategories(subjectId); // Fetch subcategories
        }
        onSubjectSelect(subjectId); // Trigger subject select handler
    };

    // Handle subcategory click
    const handleSubcategoryClick = (subcategoryId) => {
        onSubcategorySelect(subcategoryId); // Trigger subcategory select handler
        setDropdownVisible(null); // Close dropdown after selection
    };

    const location = useLocation();

    return (
        <>
            <div className='header'>
                <nav>
                    <ul className="nav-links">
                        <li><Link to={'/'}>Home</Link></li>
                        <li><Link to={'/mcqsubmit'}>Submit Mcq</Link></li>
                        <li><Link to={'/about-us'}>About</Link></li>
                        <li><Link to={'/contact-us'}>Contact Us</Link></li>
                        {
                            location.pathname === '/' &&
                            <li 
                                className={`dropdown-container ${dropdownVisible ? 'active' : ''}`}
                                onMouseLeave={() => setDropdownVisible(null)}
                            >
                                <Link to="#">Subjects</Link>
                                <ul className="dropdown">
                                    {subjects.map(subject => (
                                        <li 
                                            key={subject.id} 
                                            onClick={() => handleSubjectClick(subject.id)} // Handle mobile tap/click
                                            className={`dropdown-item ${dropdownVisible === subject.id ? 'active' : ''}`}
                                        >
                                            {subject.name}
                                            {/* Show the dropdown arrow only if the subject has subcategories */}
                                            {subjectHasSubcategories[subject.id] && (
                                                <span className="dropdown-arrow">▼</span>
                                            )}
                                            {dropdownVisible === subject.id && subcategoriesDropdown.length > 0 && (
                                                <>
                                                    <ul className="subcategory-dropdown">
                                                        {subcategoriesDropdown.map(subcategory => (
                                                            <li 
                                                                key={subcategory.id} 
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Prevent triggering subject click
                                                                    handleSubcategoryClick(subcategory.id);
                                                                }}
                                                                className="dropdown-item"
                                                            >
                                                                {subcategory.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        }
                    </ul>
                </nav>
                <h1 className="logo-text" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} alt="Logo" 
                        style={{  
                            height: '100px', 
                            marginRight: '10px', 
                            borderRadius: '50%', 
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                            border: '2px solid #ddd' 
                        }} 
                    />
                </h1>
            </div>
        </>
    );
}
