import React from 'react';
import './App.css';
import MCQList from '../components/MCQList';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

function App() {
  return (
    <>
    <div className="App">
      <MCQList />
    </div>
    <Footer />
    </>
  );
}

export default App;


