import React from 'react'
import Header from '../Common/Header'

export default function Error404() {
  return (
    <div>
        <Header/>
        <h1> Error 404 </h1>
    </div>
  )
}
