import React, { useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './Contact.css'; // Import your CSS file

export default function Contact() {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    setMessage('Thank you for your message!'); // Set the message
    // Optionally, reset the form fields if desired
    e.target.reset();
  };

  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <div className="contact-container">
        <h1 className='contachheading'>Contact Us</h1>
        <p>Feel free to reach out to us for any inquiries or feedback.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" placeholder="Your Name" required />
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" placeholder="Your Email" required />
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" placeholder="Your Message" required></textarea>
          <button type="submit">Submit</button>
        </form>
        {/* Display the message after form submission */}
        {message && <p className="success-message">{message}</p>}
      </div>
      <Footer />
    </div>
  );
}
