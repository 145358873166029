import React from 'react';
import { Container, CardContent, Typography } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './TermsOfService.css'; // Reusing the same CSS for design consistency

const CookiePolicy = () => {

  window.scrollTo(0, 0);
  return (
    <>
      <Header />
      <Container maxWidth="md" className="terms-service-container">
        <CardContent>
          <h1 className="terms-service-title">Cookie Policy</h1>

          <Typography variant="body1" gutterBottom className="terms-service-text">
            At <strong>GlobeMcqs.com</strong>, we use cookies and similar tracking technologies to enhance user experience, improve website performance, and analyze traffic patterns. This Cookie Policy outlines how we collect and manage cookies when you visit our website.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>1. What Are Cookies?</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you access a website. Cookies help enhance your browsing experience and provide functionality, such as user preferences and login details.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>2. Why Do We Use Cookies?</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            We use cookies to ensure our website functions properly, to track website traffic, and to improve your experience on our site. We may also use cookies for marketing and advertising purposes.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>3. Types of Cookies We Use</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            <strong>3.1 Essential Cookies:</strong> These are necessary for the website to function correctly.
            <br />
            <strong>3.2 Performance Cookies:</strong> These cookies collect information about how visitors use our site.
            <br />
            <strong>3.3 Targeting Cookies:</strong> These cookies track browsing habits to help display relevant ads to users.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>4. Managing Cookies</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            You can control and manage cookies through your browser settings. Most browsers allow you to refuse or delete cookies, but please note that disabling cookies may impact the functionality of our site.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>5. Third-Party Cookies</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            We may use third-party cookies to collect information and provide analytics or advertisements. These third-party cookies are subject to the respective privacy policies of the providers.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>6. Changes to This Policy</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            We may update this Cookie Policy from time to time. Any changes will be posted on this page, and we encourage you to review it regularly.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
            <strong>7. Contact Us</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className="terms-service-text">
            If you have any questions about our Cookie Policy, please contact us at <strong>asifayub214@gmail.com</strong>.
          </Typography>
        </CardContent>
      </Container>
      <Footer />
    </>
  );
};

export default CookiePolicy;
