// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Card, CardContent, Typography, TextField, Button, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
// import Header from '../Common/Header';
// import Footer from '../Common/Footer';

// const MCQSubmit = () => {
//   const [formData, setFormData] = useState({
//     question: '',
//     option1: '',
//     option2: '',
//     option3: '',
//     option4: '',
//     correct_answer: '',
//     explanation: '',
//     subject: ''
//   });

//   const [subjects, setSubjects] = useState([]);

//   useEffect(() => {
//     // Fetch subjects from the API
//     axios.get('https://backend.globemcqs.com/api/subjects/')
//       .then(response => {
//         setSubjects(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching subjects', error);
//       });
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     axios.post('https://backend.globemcqs.com/api/create-mcq/', formData)
//       .then(response => {
//         alert('MCQ submitted successfully');
//         setFormData({
//           question: '',
//           option1: '',
//           option2: '',
//           option3: '',
//           option4: '',
//           correct_answer: '',
//           explanation: '',
//           subject: ''
//         });
//       })
//       .catch(error => {
//         console.error('Error submitting MCQ', error);
//         alert('Error submitting MCQ');
//       });
//   };

//   return (
//     <>
//       <Header />
//       <div style={{ padding: '20px' }}>
//         <Typography
//           variant="h4"
//           style={{
//             textAlign: 'center',
//             marginBottom: '20px',
//             fontWeight: 700
//           }}
//         >
//           Submit Your MCQ
//         </Typography>
//         <Card
//           style={{
//             borderRadius: '12px',
//             boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
//             backgroundColor: '#fff',
//             transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//             maxWidth: '800px',
//             margin: 'auto',
//             padding: '20px',
//             marginBottom: '30px'
//           }}
//         >
//           <CardContent>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     name="question"
//                     label="Question"
//                     variant="outlined"
//                     value={formData.question}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     fullWidth
//                     name="option1"
//                     label="Option 1"
//                     variant="outlined"
//                     value={formData.option1}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     fullWidth
//                     name="option2"
//                     label="Option 2"
//                     variant="outlined"
//                     value={formData.option2}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     fullWidth
//                     name="option3"
//                     label="Option 3"
//                     variant="outlined"
//                     value={formData.option3}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                   <TextField
//                     fullWidth
//                     name="option4"
//                     label="Option 4"
//                     variant="outlined"
//                     value={formData.option4}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     name="correct_answer"
//                     label="Correct Answer"
//                     variant="outlined"
//                     value={formData.correct_answer}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     name="explanation"
//                     label="Explanation"
//                     variant="outlined"
//                     value={formData.explanation}
//                     onChange={handleChange}
//                     multiline
//                     rows={4}
//                   />
//                 </Grid>
//                 {/* Subject Select Field */}
//                 <Grid item xs={12}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel>Subject</InputLabel>
//                     <Select
//                       name="subject"
//                       label="subject"
//                       value={formData.subject}
//                       onChange={handleChange}
                      
//                     >
//                       {subjects.map((subject) => (
//                         <MenuItem key={subject.id} value={subject.id}>
//                           {subject.name}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     style={{ width: '100%' }}
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </form>
//           </CardContent>
//         </Card>
//       </div>
//       <Footer/>
//     </>
//   );
// };

// export default MCQSubmit;


// just showing message not submitting to db.
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, TextField, Button, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './MCQSubmit.css';

const MCQSubmit = () => {
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    correct_answer: '',
    explanation: '',
    subject: ''
  });

  const [subjects, setSubjects] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Fetch subjects from the API
    axios.get('https://backend.globemcqs.com/api/subjects/')
      .then(response => {
        setSubjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching subjects', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setMessage('Your MCQ is under observation for submission.');
    // Clear the form after showing the message
    setFormData({
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      correct_answer: '',
      explanation: '',
      subject: ''
    });
  };

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <div style={{ padding: '20px' }}>
        <Typography
          variant="h4"
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            fontWeight: 700
          }}
        >
          {/* Submit Your MCQ */}
        </Typography>
        <Card
          style={{
            borderRadius: '12px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f9f9f9',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            maxWidth: '800px',
            margin: 'auto',
            padding: '20px',
            marginBottom: '30px'
          }}
        >
          <h1 className='submitheading'>Submit Your MCQ</h1>
          <CardContent>
            <form onSubmit={handleUpdate}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="question"
                    label="Question"
                    variant="outlined"
                    value={formData.question}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="option1"
                    label="Option 1"
                    variant="outlined"
                    value={formData.option1}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="option2"
                    label="Option 2"
                    variant="outlined"
                    value={formData.option2}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="option3"
                    label="Option 3"
                    variant="outlined"
                    value={formData.option3}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="option4"
                    label="Option 4"
                    variant="outlined"
                    value={formData.option4}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="correct_answer"
                    label="Correct Answer"
                    variant="outlined"
                    value={formData.correct_answer}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="explanation"
                    label="Explanation"
                    variant="outlined"
                    value={formData.explanation}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                {/* Subject Select Field */}
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Subject</InputLabel>
                    <Select
                      name="subject"
                      label="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    >
                      {subjects.map((subject) => (
                        <MenuItem key={subject.id} value={subject.id}>
                          {subject.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    style={{ width: '100%' }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              {/* Display message for update */}
              {message && (
                <Typography variant="body1" style={{ color: 'green', textAlign: 'center', marginTop: '20px' }}>
                  {message}
                </Typography>
              )}
            </form>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default MCQSubmit;

