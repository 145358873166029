import React from 'react';
import ReactDOM from 'react-dom/client';
import './Pages/index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Error404 from './Pages/Error404';
import App from './Pages/App';
import Contact from './Pages/Contact';
import About from './Pages/About';
import MCQSubmit from './Pages/MCQSubmit';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import CookiePolicy from './Pages/CookiePolicy';



const root = ReactDOM.createRoot(document.getElementById('root'));

let allRoutes = createBrowserRouter(
  [
    {
      path: '/',
      element: <App/>
    },
    
    {
      path: 'about-us',
      element: <About/>
    },
    {
      path: 'mcqsubmit',
      element: <MCQSubmit/>
    },

    {
      path: 'contact-us',
      element: <Contact/>
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy/>
    },
    {
      path: 'terms-of-Service',
      element: <TermsOfService/>
    },
    {
      path: 'cookie-policy',
      element: <CookiePolicy/>
    },

    {
      path: '*',
      element: <Error404/>
    }
  ]
);

root.render(
  <React.StrictMode>
    <RouterProvider router={allRoutes}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();