import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MCQCard from '../components/MCQCard';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import './Pagination.css';
import Header from '../Common/Header';

const MCQList = () => {
    const [mcqs, setMcqs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showAnswers, setShowAnswers] = useState(true);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Track selected subcategory

    // Fetch MCQs based on selected subject and/or subcategory
    useEffect(() => {
        let mcqUrl = '';

        // When both subject and subcategory are selected
        if (selectedSubject && selectedSubcategory) {
            mcqUrl = `https://backend.globemcqs.com/api/mcqs-by-subject/${selectedSubject}/${selectedSubcategory}/?page=${currentPage}`;
        } 
        // When only subcategory is selected, fetch by subcategory directly
        else if (selectedSubcategory) {
            mcqUrl = `https://backend.globemcqs.com/api/mcqs-by-subcategory/${selectedSubcategory}/?page=${currentPage}`;
        } 
        // When only subject is selected
        else if (selectedSubject) {
            mcqUrl = `https://backend.globemcqs.com/api/mcqs-by-subject/${selectedSubject}/?page=${currentPage}`;
        } 
        // When neither subject nor subcategory is selected, fetch all MCQs
        else {
            mcqUrl = `https://backend.globemcqs.com/api/mcqs/?page=${currentPage}`;
        }

        axios.get(mcqUrl)
            .then(response => {
                setMcqs(response.data.results);
                setTotalPages(response.data.total_pages);
            })
            .catch(error => {
                console.log("Error fetching data", error);
            });
    }, [currentPage, selectedSubject, selectedSubcategory]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const toggleAnswers = () => {
        setShowAnswers(prev => !prev);
    };

    // Handle subject selection
    const handleSubjectSelect = (subjectId) => {
        setSelectedSubject(subjectId);
        setSelectedSubcategory(null); // Reset subcategory when new subject is selected
        setCurrentPage(1);
    };

    // Handle subcategory selection
    const handleSubcategorySelect = (subcategoryId) => {
        setSelectedSubcategory(subcategoryId);
        setSelectedSubject(null); // Reset subject when new subcategory is selected
        setCurrentPage(1);
    };

    return (
        <div>
            {/* Pass subject and subcategory handlers to Header */}
            <Header
                onSubjectSelect={handleSubjectSelect}
                onSubcategorySelect={handleSubcategorySelect}
                selectedSubject={selectedSubject}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={toggleAnswers}
                style={{ marginBottom: '20px' }}
            >
                {showAnswers ? 'Try Quiz Mode' : 'Reading Mode'}
            </Button>

            <div>
                {mcqs.map(mcq => (
                    <MCQCard
                        key={mcq.id}
                        mcq={mcq}
                        showAnswer={showAnswers} // Pass showAnswer to MCQCard
                    />
                ))}
            </div>

            <div className="pagination-container">
                <Stack spacing={2} className="pagination-stack">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        shape="rounded"
                    />
                </Stack>
            </div>
        </div>
    );
};

export default MCQList;
