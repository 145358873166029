import React from 'react';
import { Container, Card, CardContent, Typography } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Header />
      <Container maxWidth="md" className="security-policy-container">
        
          <CardContent>
             
            <h1 className="security-policy-title">Privacy Policy</h1>

            <Typography variant="body1" gutterBottom className="security-policy-text">
              At <strong>GlobeMcqs.com</strong>, we take the security and privacy of our users seriously. Our website follows strict guidelines to protect your data and provide a safe browsing experience.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong>1. Data Encryption </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              All data transmitted between our users and the server is encrypted using SSL (Secure Socket Layer). We enforce HTTPS across the website to ensure data integrity and confidentiality.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong>2. User Authentication</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              We use secure authentication mechanisms, such as hashed passwords and multi-factor authentication (MFA), to protect user accounts. We recommend our users follow best practices for password security.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong>3. Account Security</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              Users are responsible for maintaining the confidentiality of their login information. We advise the following:
              <br />
              <strong>3.1</strong> Use a strong, unique password for your account.
              <br />
              <strong>3.2</strong> Regularly update your password.
              <br />
              <strong>3.3</strong> Enable two-factor authentication for added security.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong> 4. Protection Against Attacks</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              We employ security measures such as:
              <br />
              <strong>4.1</strong> Firewalls to prevent unauthorized access.
              <br />
              <strong>4.2</strong> Regular security audits to identify and mitigate potential vulnerabilities.
              <br />
              <strong>4.3</strong> Rate-limiting and CAPTCHA to protect against brute force attacks.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong>5. Reporting Vulnerabilities </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              We encourage responsible disclosure. If you discover a security vulnerability, please contact us immediately at <strong>asifayub214@gmail.com</strong>. We will investigate and address the issue promptly.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong> 6. Privacy Protection </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              Our users' privacy is protected by limiting the collection of personal data and ensuring it is used strictly for the intended purpose. Refer to our <strong>Privacy Policy</strong> for more details.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong> 7. Third-Party Services </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              We only partner with trusted third-party services that adhere to strict security standards. Your data is never shared without your consent.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong> 8. Regular Updates </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              We regularly update our website to protect against known vulnerabilities and emerging security threats.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="security-policy-subheading">
            <strong> 9. User Responsibility </strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="security-policy-text">
              While we implement best practices, users are encouraged to:
              <br />
              <strong>9.1</strong> Avoid sharing account details.
              <br />
              <strong>9.2</strong> Log out after each session.
              <br />
              <strong>9.3</strong> Report any suspicious activity.
            </Typography>
          </CardContent>
        
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
