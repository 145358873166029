import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../Assets/logo.jpeg';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h1 className="logo-text">
            <img src={logo} alt="Logo" style={{ height: '100px', marginRight: '10px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', border: '2px solid #ddd' }} />
          </h1>
          <p>
          GlobeMCQs provides expertly curated MCQs to enhance your exam preparation efficiently.
          </p>
          <div className="contact">

            <span><i className="fas fa-envelope"></i> asifayub214@gmail.com</span>
          </div>
          <div className="socials">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
          </div>
        </div>
        <div className="footer-section links">
          <h2 style={{ textAlign: 'center', padding: 0 }}>Quick Links</h2>
          <ul>
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/about-us'}>About</Link></li>
            <li><Link to={'/contact-us'}>Contact Us</Link></li>
            <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
            <li><Link to={'/terms-of-service'}>Terms Of Service</Link></li>
            <li><Link to={'/cookie-policy'}>Cookie Policy</Link></li>
          </ul>
        </div>
      </div>
      <p className="footer-bottom-text">
        © 2024 <a href="https://globemcqs.com" target="_blank" rel="noopener noreferrer">GlobeMcqs.com</a> All rights reserved.
      </p>
    </footer>
  );
}
