import React from 'react';
import { Container, CardContent, Typography } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import './TermsOfService.css';

const TermsOfService = () => {

  window.scrollTo(0, 0);
  return (
    <>
      <Header />
      <Container maxWidth="md" className="terms-service-container">
        
          <CardContent>
             
            <h1 className="terms-service-title">Terms of Service</h1>

            <Typography variant="body1" gutterBottom className="terms-service-text">
              Welcome to <strong>GlobeMcqs.com</strong>, a platform that offers test and exam preparation data across multiple subjects. By using our services, you agree to the following terms.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>1. Acceptance of Terms</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              By accessing or using GlobeMcqs.com, you agree to comply with and be bound by these Terms of Service. If you do not agree, please do not use our site.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>2. User Accounts</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              When you create an account on GlobeMcqs.com, you are responsible for maintaining the confidentiality of your login information and for any activity under your account.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>3. Intellectual Property</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              All content on GlobeMcqs.com, including questions, answers, and explanations, is the intellectual property of GlobeMcqs.com and is protected by copyright laws.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>4. Prohibited Activities</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              You agree not to engage in any of the following prohibited activities:
              <br />
              <strong>4.1</strong> Unauthorized copying or distribution of content.
              <br />
              <strong>4.2</strong> Misusing the website for unlawful purposes.
              <br />
              <strong>4.3</strong> Attempting to compromise the security of GlobeMcqs.com.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>5. Limitation of Liability</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              GlobeMcqs.com is not liable for any damages resulting from the use of our services, including but not limited to exam results or loss of data.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>6. Amendments to the Terms</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              We reserve the right to amend these Terms of Service at any time. Users will be notified of significant changes via email or announcements on the website.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom className="terms-service-subheading">
              <strong>7. Contact Us</strong>
            </Typography>
            <Typography variant="body1" gutterBottom className="terms-service-text">
              If you have any questions regarding these Terms of Service, please contact us at <strong>asifayub214@gmail.com</strong>.
            </Typography>

          </CardContent>
        
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfService;
